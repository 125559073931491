.Header {
  height: var(--header-height);
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 2rem;
}

.HeaderUser {
  margin-right: 2rem;
}
