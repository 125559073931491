.CronFilters {
  display: flex;
}
.CronFiltersLeft {
  flex: 1;
}
.CronFiltersRight {
  display: flex;
  align-items: center;
}

.CronFiltersRight span {
  margin-right: 4rem;
}
 