.SideMenu {
  display: block;
  background-color: var(--primary-color);
}

.Menu a {
  color: #fff;
  opacity: 0.8;
  --bs-link-color: #fff;
  text-decoration: none;
}

.Menu a.MenuActive {
  opacity: 1;
}
.SideMenu ul {
  list-style: none;
}
.SideMenu li a {
  display: block;
  margin: 2rem 0;
}
.SideMenu .Icon {
  margin-right: 1rem;
  font-size: 1.4rem;
}
