.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--light-grey);
}

.auth-box {
  padding: 2rem;
  border: 1px solid var(--grey);
  background-color: #fff;
  width: 35rem;
}

.auth-logo {
  height: 5rem;
  margin: auto;
  display: block;
  margin-bottom: 2rem;
}
