:root {
  --primary-color: hsl(318, 84%, 36%);
  --primary-color-darker: hsl(318, 83%, 26%);
  --bs-primary: var(--primary-color);
  --light-grey: #f8f9fc;
  --border-light: #dee2e6;
  --grey: #e9e9e9;

  --header-height: 7rem;
  --font-size-base: 1.6rem;
  --font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";

  --bs-body-font-size: var(--font-size-base);
  --bs-link-color: var(--primary-color);
  --bs-link-hover-color: var(--primary-color-darker);

  --bs-primary-rgb: 169, 15, 123;
}

html {
  height: 100%;
  font-size: 62.5%;
}
* {
  box-sizing: border-box;
}
body {
  font-size: var(--font-size-base);
  font-family: var(--font-family), sans-serif;
  height: 100%;
  margin: 0;
}
.input-group {
  margin-bottom: 2rem;
}
.height-75 {
  height: 75vh;
}
.form-control,
button.btn {
  font-size: 1.6rem;
  height: 4rem;
}
.nav {
  --bs-nav-link-padding-x: 2rem;
  --bs-nav-link-padding-y: 1rem;
}
.pagination {
  --bs-pagination-active-border-color: var(--primary-color);
  --bs-link-color: var(--primary-color);
  --bs-pagination-active-bg: var(--primary-color);
  --bs-link-hover-color: var(--primary-color);
  --bs-pagination-font-size: var(--font-size-base);
  --bs-pagination-padding-x: 1.25rem;
}
.pagination .page-link {
  cursor: pointer;
}
.btn-primary {
  --bs-btn-bg: var(--primary-color);
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-disabled-border-color: var(--primary-color);
  --bs-btn-hover-bg: var(--primary-color-darker);
  --bs-btn-active-bg: var(--primary-color-darker);
  --bs-btn-focus-shadow-rgb: 168, 16, 122;
  --bs-btn-hover-border-color: var(--primary-color-darker);
  /* --bs-btn-focus-box-shadow: none; */
}
.form-select {
  font-size: var(--font-size-base);
  padding-block: 1rem;
}
.btn {
  --bs-btn-padding-x: 2rem;
}
#root {
  background-color: var(--light-grey);
  height: 100%;
}
/* html,
#root,
main {
  min-height: 100vh;
} */

.content {
  background-color: #fff;
  padding: 3rem;
  margin-top: 2rem;
}
