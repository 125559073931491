.Layout {
  height: 100%;
  display: grid;
  grid-template-columns: 20rem max(calc(100% - 20rem));
}

.LayoutContent main {
  height: calc(100vh - var(--header-height));
  padding: 2rem;
  overflow: auto;
}
