.SideMenuHeader {
  display: flex;
  align-items: center;
  padding: 2rem;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.SideMenuLogo {
  width: 4rem;
  margin-right: 2rem;
}
